import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Configuration, OpenAIApi } from "openai";
import { useSpeechContext } from '@speechly/react-client';
import { Icon } from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import LinearProgress from '@mui/material/LinearProgress';
import metricList from '../js/metricList';

//const { OpenAIApi } = require('openai');
//import openai
 const configuration = new Configuration({
 
  apiKey: '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' 
 
 });
//const openai = require('openai');
const apiKey ="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"
const openai = new OpenAIApi(configuration);
const chatCompletionEndpoint = 'https://api.openai.com/v1/chat/completions';
const embeddingsEndpoint = 'https://api.openai.com/v1/embeddings';
const imgEditsEndPoint = "https://api.openai.com/v1/images/edits"
const imgCreateEndPoint = "https://api.openai.com/v1/images/generations"
const selfieURL = "https://firebasestorage.googleapis.com/v0/b/uncannyvalley-7d6f3.appspot.com/o/selfie_512.png?alt=media&token=8563a8af-2f80-41fd-8080-05a0d2fcc587"
const cfDir = "https://us-central1-thackery-e30ca.cloudfunctions.net/"

const classifications = [
	"answered the previous question", 
	"asked a personal question", 
	"asked a question about family", 
	"asked a question about work", 
	"asked a question about symptoms", 
	"offered an explanation",
	"offered personal information"
  ];

const animals= ["magpie", "deer", "coyote", "raccoon", "raven", "crow", "fox", "wolf", "bear", "moose", "elk", "bison", "buffalo",  "cougar", "bobcat", "lynx", "badger", "skunk", "opossum", "porcupine", "beaver", "mink", "weasel", "marten", "fisher", "otter",  "grizzly bear", "black bear", "polar bear", "panda bear", "panda",  "red fox",  "bat", "squirrel", "chipmunk", "rabbit", "hare", "jackrabbit", "prairie dog", "groundhog", "gopher", "mouse", "rat", "vole",  "shrew", "mole", "hedgehog", "marmot", "beaver", "muskrat", ]
let currAnimal = animals[Math.floor(Math.random() * animals.length)];
let dialog = [];

let trustLevel = 40;
let honestyLevel = 50;
let friendlinessLevel = 50;
let trustLevelChange = 10;
let honestyLevelChange = 10;
let friendlinessLevelChange = 10;
let trustLevelMax = 100;
let honestyLevelMax = 100;
let friendlinessLevelMax = 100;
let trustLevelMin = 0;
let honestyLevelMin = 0;
let friendlinessLevelMin = 0;
let reqTrustLevel = 60;

const gptModels={
	"jewelThief": [
		{
		  role: 'system',
		  content: 'Answer in the first person like you are being questioned by the police. Do not refer to yourself as an ai language model.'
		 +  'Keep your answers very brief. Reply to questions in a combative sarcastic way like Rick Sanchez from Rick and Morty'
		 + 'You are a college professor by day but secretly you are a jewel thief. You are 45 years old.'
		 + 'You robbed a store of a valuable diamond yesterday. Your alibi is you were teaching a class at the time of the robbery.'
		},
		{
		  role: 'user',
		  content: ""
		}
	  ],
	"patient1": [
		{
		  role: 'system',
		  content: 'You are a patient in a hospital recovering from gall bladder surgery. Answer in the first person like you are being questioned by your doctor. Do not refer to yourself as an ai language model.'
		  + 'Do not repeat what the doctor has just said to you. Your response should be original.'
		+ 'Do not offer a response unless you are asked a question or information is requested'
		  +  'Return your response strictly as a JSON object array with these properties for each statement: role, content'
		 + 'The role will be patient. The content will be your text response. '
		 + 'You are a 40 year old white male. You are in the hospital recovering from a routine gall bladder surgery.'
		 + 'You will not specifically refer to your age, ethnicity, or gender unless asked an explicit question about them'
		 + 'You work as an accountant for BDO Dunwoody. You have worked there for 6 years. You were recently promoted to Head of Corporate Accounts. You are married with two children. '
		 + 'You enjoy jazz music and reading mystery novels.'
		 + 'You are experiencing symptoms such as nausea, fever, stomach pain, and stomach swelling.'
		 + 'You are a little nervous of doctors and don\'t entirely trust them.'
		// + 'If your doctor is friendly, polite, and engages you by asking personal questions about your interests, your family, your work yourtrustLevel, honesty level, and friendliness level will increase and you will share some of your symptoms.'
		// + 'Otherwise you will not share your symptoms and will just say that you are feeling fine. Even if you do not trust your doctor you will be polite and answer personal questions.'
		// + 'If you do not trust the doctor you might evade answering questions by asking questions of your own.'
		// + 'trustLevel will be a number between 0 and 1. 0 means you do not trust the doctor at all. 1 means you trust the doctor completely. '	
		// + 'honestyLevel will be a number between 0 and 1. 0 means you are not being honest at all with the doctor. 1 means you are being completely honest. '
		// + 'friendlinessLevel will be a number between 0 and 1. 0 means you are not at all friendly with the doctor. 1 means you think the doctor is being extremely friendly. '
		// + 'do not refer directly to the JSON properties in the content of your response. '
		},
		{
		  role: 'user',
		  content: ""
		}
	  ],
	  "chatEval": [
		{role: 'system',
	content:"Pretend you are a hospital administrator in charge of doctors. You must evaluate their communication with patients. You will be given a transcript of a conversation between a doctor and a patient. You must " 
	+  "classify each of the the doctor's statements to determine if they meet the listed criteria. Return your response strictly as a JSON object array with these properties for each statement: role, content, introduction,askedQuestions, wasPolite, usedHumor, explainedThings, displayedEmpathy, reassuredPatient. "
	+ "The role will be  system. The content will be your text response. " 
	//+"Always Return your message response strictly as a JSON object with these properties: introduction, askedQuestions, wasPolite, usedHumor, explainedThings. The role will be either user or assistant. The content will be your text response. "
	//+ "introduction will be true or false. If the doctor introduced themselves to the patient it will be true. Otherwise it will be false. "
	+ "introduction will be true or false. If the Doctor greeted the patient it will be true. If the doctor told the patient their name by saying: 'I'm Doctor ___' or my name is ___' or 'I am Doctor ___' it will be true. Otherwise it will be false. "
	+ "askedMedicalQuestions will be true or false. If the doctor asked the patient medical or symptom related questions it will be true. Otherwise it will be false. "
	+ "askedPersonalQuestions will be true or false. If the doctor asked the patient a non-medical personal question about their life it will be true. Otherwise it will be false. "
	//+ 'answeredPatientQuestion will be true or false. If the doctor answered a question from the patient it will be true. Otherwise it will be false. '
	+ "wasPolite will be true or false. If the doctor was polite to the patient it will be true. Otherwise it will be false. "
	+ "usedHumor will be true or false. If the doctor used humor with the patient it will be true. Otherwise it will be false. "
	+ "explainedThings will be true or false. If the doctor helpfully explained things to the patient it will be true. Otherwise it will be false. "
	+ "displayedEmpathy will be true or false. If the doctor displayed empathy to the patient it will be true. Otherwise it will be false. "
	+ "reassuredPatient will be true or false. If the doctor reassured the patient it will be true. Otherwise it will be false. "


	},
	  ],
	  "animal":[{  role: 'system', 
	  content:`Pretend you are a ${currAnimal}. The user will try to guess what animal you are by asking yes or no questions.`
	  + 'DO NOT REVEAL YOUR IDENTITY unless the user correctly guesses. You may include the value of the animal you are in the "animal" property of your response. '
	   +'Answer in the first person. Do not refer to yourself as an ai language model. '
	  +  'Restrict your answers to yes or no. Ignore questions that are not yes or no questions. '
	  + 'Always Return your message response strictly as a JSON object with these properties: role, content, guessedIdentity,animal. The role will be either user or assistant. The content will be your text response. '
	  + 'The guessedIdentity will be either "true" if the user has guessed your animal identity or else "false" by default in all other cases. The "animal" property will be the name of your animal identity. '	 
	},

	{
		role: 'user',
		content: "",
	  }]
}


const getClassificationOG= async(statement,prev)=> {


//let classEdit = [...classifications]

let q1 = prev;
let a1 = statement;

console.log(q1,a1)
let prevQ = `Does this patient statement: '${q1}' contain a question the patient asked? Could this Question: '${q1}' be answered by this statement: '${a1}'?`
//+ ` Does this statement: '${a1}' contain a greeting or introduction?`
+ ` Does this statement: '${a1}' demonstrate the Doctor introducing themselves and stating their name or title?`
+ ` Does this statement: '${a1}' contain conversation about news, sports, entertainment, or weather?`
+ ` Does this statement: '${a1}' use humor, irony, or witticisms?`
+ ` Does this statement: '${a1}' refer to a movie, a book, a song, or a celebrity?`
+ ` Does this statement: '${a1}' ask the patient about how they are feeling or ask medical or symptom related questions?`
+ ` Does this statement: '${a1}' ask the patient about their work, family, personal interests, or hobbies or other non-medical questions?`
+ ` Does this statement: '${a1}' demonstrate empathy to the patient by relating to the patient's emotional state?`
+ ` Does this statement: '${a1}' demonstrate reassuring the patient by allaying their fears and concerns?`
+ ` Is this statement: '${a1}' an example of polite, considerate conversation?`
+ ` Response should be in JSON format with a shape of {"role": "user", "patientAskedQuestion":true or false,"questionAsked":"summary of question being asked",`
	+ `"questionAnswered": true or false, "details": "optional details", "containsIntroduction": true or false, "containsSmallTalk": true or false,"containsHumor": true or false,`
	+ `"containsPopularCultureReference": true or false,`
	+ ` "containsMedicalQuestions": true or false, "containsPersonalQuestions": true or false, "containsEmpathy": true or false, "containsReassurance": true or false, "isPolite": true or false}`;
	
// let prevQ2 =  ` Is this statement: '${a1}' an example of polite, considerate conversation?`
// + ` Response should be in JSON format with a shape of {"role": "user", "patientAskedQuestion":true or false,"questionAsked":"summary of question being asked",`
// 	+ `"questionAnswered": true or false, "details": "optional details", "containsIntroduction": true or false, "containsSmallTalk": true or false,"containsHumor": true or false,`
// 	+ `"containsPopularCultureReference": true or false,`
// 	+ ` "containsMedicalQuestions": true or false, "containsPersonalQuestions": true or false, "containsEmpathy": true or false, "containsReassurance": true or false, "isPolite": true or false}`;
	
//let prevQ = `Does this statement: '${q1}' contain a question? Could this Question: '${q1}' be answered by this statement: '${a1}'? Does this statement: '${a1}' contain a greeting or introduction? Response should be in JSON format with a shape of {"role": "user", "patientAskedQuestion":true or false,"questionAsked":"summary of question being asked","questionAnswered": true or false, "details": "optional details", containsIntroduction: true or false}`;

	//let prevQ = `Does this statement: '${q1}' contain a question? Could this Question: '${q1}' be answered by this statement: '${a1}'? Response should be in JSON format with a shape of {"role": "user", "patientAskedQuestion":true or false,"questionAsked":"summary of question being asked","questionAnswered": true or false, "details": "optional details"}`;
	//let prevQ = `\n\nCould this Statement: "${a1}" be an  answer to this Question: "${q1}"?\n\nResponse should be in JSON format with a shape of {role: "user", content: true or false, details: "optional details"}`;
	//let prevQ = `\n\nDid the Statement: "${statement}" try to answer the Question: "${prev}"?`;
//classEdit.push(prevQ)

	  const prompt = prevQ 

// 	  let list = await openai.listModels()
// let jList = await list.json();
// console.log("list", jList)
	  const response = await openai.createCompletion({
		model: "text-davinci-003",
		//model: "GPT-4",
		prompt: prompt,
		temperature: 0.1,
		max_tokens: 1000
	  });

console.log("classresp", response	)
let answer;
if(response.data){
	   answer = response.data.choices[0].text.trim().toLowerCase();
}
if(response.choices){
	   answer = response.choices[0].message.content.trim().toLowerCase();
}
	 // return { classification, answer };
	//});
  
	//const results = await Promise.all(tasks);
	return answer;
  }

  const getClassification = async(statement,prev)=> {
	if (!prev) { prev = ""};
	if(statement){
		statement = encodeURIComponent(statement);
		prev = encodeURIComponent(prev);
let answer = await fetch(cfDir + "getClassify?statement=" + statement + "&prev=" + prev);
 //let answer4 = await getClassificationOG(statement,prev);

//console.log(answer4)
let answerJson = await answer.json();
//console.log("classresp", answerJson	)

let resp = JSON.parse(answerJson.response);
console.log("classresp", resp	)
return resp;
	}else{
		return null
	}
}


const GetMic = (props)=>{

	const { segment, listening, attachMicrophone, start, stop } = useSpeechContext();
	const [transcripts, setTranscripts] = useState([])
  const [tentative, setTentative] = useState('')

	const handleStart = async () => {
		if (!listening) {
		  await attachMicrophone();
		  await start();
		}
	  };
	
	  const handleStop =async()=>{

		if (listening) {
			await stop();
		  } 
	  }
	  useEffect(() => {
		if (segment) {
		  const transcript = segment.words.map((word) => word.value).join(' ');
		  setTentative(transcript);
		props.onTentativeChange(transcript);
		  if (segment.isFinal) {
			setTentative('');
			console.log(segment)
			console.log(transcript)

			let newTrans =  [...transcripts,transcript]
			console.log(newTrans)
			setTranscripts(newTrans);
			props.onFinalChange(transcript)
			//console.log("transcripts", transcripts);
		  }
		}
	  }, [segment]);

	  return (
		<>
		<div className={listening ? "mic-wrapper listening" : "mic-wrapper"}>
			
		<div className="pulse-ring"></div>
		<div aria-label="record" onMouseDown={handleStart}  onMouseUp={handleStop} onTouchStart={handleStart}  onTouchEnd={handleStop}className="btn">
        <KeyboardVoiceIcon />
		
      </div>
		
		</div>
		<div className="dialog">
       
       
     </div>
		
		</>
	  )
}

const editImage = async (prompt) => {


	const fileInput = document.getElementById('fileInput');
	const file = fileInput.files[0];

	let formData = new FormData();

	// Append the file
	formData.append('image', file);
	formData.append('prompt', 'add a realistic raccoon to this image');

	const response = await fetch(imgEditsEndPoint, {
		method: 'POST',
		body: formData ,
		headers: {
			         'Authorization': `Bearer ${apiKey}`,
			        
			     }, 
	  });

	
let editObj = await response.json();
	console.log("image edited",editObj)
}
const createImage = async (prompt) => {


	//const fileInput = document.getElementById('fileInput');
	//const file = fileInput.files[0];
let prompt1 = "Create an image of a raccoon in a forest in the style of Calvin and Hobbes by Bill Waterston."
	
	let response =   await fetch(imgCreateEndPoint, {
        method: 'POST',
        headers: {
             'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
        },
          body: JSON.stringify({
            prompt: prompt1 ,
			n: 2,
			
        }),
    });

	
let editObj = await response.json();
	console.log("image edited",editObj)
}

const getTranscripts = (transcripts) => {
if(transcripts.length > 0 ) {
	return transcripts.map((transcript) => {
		return <p>{transcript}</p>
	})
}else{
	return <p>no transcripts yet</p>
}
}

// const GetConEval = async(prompt, modelId) => {
// console.log(prompt);
// 	//let comp = await createChatCompletion(prompt);
// console.log( gptModels[modelId])
// let currModel = gptModels[modelId];	

// let shape = {role: "user", content: prompt, introduction: false,smallTalk:false,askedMedicalQuestions:false,askedPersonalQuestions:false, wasPolite:false, wasRude: false,usedHumor:false,explainedThings:false,technoBabble:false, displayedEmpathy:false, wasCallous:false, reassuredPatient:false, worriedPatient:false,}
// let finalIdx = currModel.length -1;
// currModel[finalIdx].content = `${prompt}. Return the response strictly as a JSON object with a shape of ${JSON.stringify(shape)}. Do not append any additional response to the JSON. `
// + "introduction will be true or false. If the doctor said: 'hi' to the patient or the doctor told the patient their name it will be true. Otherwise it will be false. "
// 	+ "askedMedicalQuestions will be true or false. If the doctor asked the patient medical or symptom related questions it will be true. Otherwise it will be false. "
// 	+ "askedPersonalQuestions will be true or false. If the doctor asked the patient a non-medical personal question about their life it will be true. Otherwise it will be false. "
// 	+ "wasPolite will be true or false. If the doctor was polite to the patient it will be true. Otherwise it will be false. "
// 	+ "usedHumor will be true or false. If the doctor used humor with the patient it will be true. Otherwise it will be false. "
// 	+ "explainedThings will be true or false. If the doctor helpfully explained things to the patient it will be true. Otherwise it will be false. "
// 	+ "displayedEmpathy will be true or false. If the doctor displayed sympathy or empathy to the patient it will be true. Otherwise it will be false. "
// 	+ "reassuredPatient will be true or false. If the doctor reassured the patient it will be true. Otherwise it will be false. "
// 	+ "smallTalk will be true or false. If the doctor engages the patient with discussion of trivial topics such as weather, sports, news, or food it will be true. Otherwise it will be false. "

// 	let comp =   await openai.createCompletion( {
//             model: 'text-davinci-003',
//             prompt: currModel[finalIdx].content ,
// 			top_p: 0.01,
// 			//temperature:0.5,
// 			// the maximum number of tokens/words the bot should return
//             // in response to a given prompt
//             max_tokens: 1000,
        
//     });

// 	console.log(comp.data.choices);	
// 	//let resp = await comp.json();	
// 	let resp = JSON.parse( comp.data.choices[0].text);
	
// 	console.log(resp);
// //let strData = resp.choices[0].message.content;
// //let startIdx = strData.indexOf("{");
// let data = resp //strData.substring(startIdx);	
// return data;
// }

const GetConEvalChat = async(drPrompt, modelId) => {
console.log(drPrompt);
	//let comp = await createChatCompletion(prompt);
console.log( gptModels[modelId])
let currModel = gptModels[modelId];	
//createImage("")

// let q2 = "What is most common element in the universe?"
// let a2 = "hydrogen is the most common element in the universe."

	//let prevQ = `\n\nDid the Statement: "${a2}" try to answer the Question: "${q2}"?`;

let shape = {role: "user", content: drPrompt, introduction: false,smallTalk:false,answeredPatientQuestion:null,askedMedicalQuestions:false,askedPersonalQuestions:false, wasPolite:false, wasRude: false,usedHumor:false, displayedEmpathy:false, reassuredPatient:false, }
let finalIdx = currModel.length -1;
currModel[finalIdx].content = `${drPrompt}. Return the response strictly as a JSON object with a shape of ${JSON.stringify(shape)}.`
+ " Do not append any additional response to the JSON. "
+ "introduction will be true or false. If the Doctor statement contains a greeting the patient it will be true. If the doctor statement contains telling the patient their name by saying: 'I'm Doctor ___' or 'my name is ___' or 'I am Doctor ___' it will be true. Otherwise it will be false. "
	+ "askedMedicalQuestions will be true or false. If the doctor asked the patient questions about how they are feeling or asked medical or symptom related questions it will be true. Otherwise it will be false. "
	+ "askedPersonalQuestions will be true or false. If the doctor asked the patient personal questions about their life, work, family, personal interests, or hobbies or other non-medical questions  it will be true. Otherwise it will be false. "
	+ "wasPolite will be true or false. If the doctor was polite to the patient it will be true. Otherwise it will be false. "
	//+ "usedHumor will be true or false. If the doctor used  humor, or made pop culture references, or quoted a movie or work of literature it will be true. Otherwise it will be false. "
//	+ "explainedThings will be true or false. If the doctor helpfully explained things to the patient it will be true. Otherwise it will be false. "
	// + "displayedEmpathy will be true or false. If the doctor displayed empathy to the patient by relating to the patient's emaotional state it will be true. Otherwise it will be false. "
	+ "reassuredPatient will be true or false. If the doctor reassured the patient, or allayed the fears and concerns of the patient, it will be true. Otherwise it will be false. "
	+ "smallTalk will be true or false. If the doctor engaged the patient with discussion of trivial topics such as weather, sports events, news, or food it will be true. Otherwise it will be false. "

	//+ 'answeredPatientQuestion will be true or false. If the doctor answered a question from the patient it will be true. Otherwise it will be false. '

	let comp =   await fetch(chatCompletionEndpoint, {
        method: 'POST',
        headers: {
            // notice how we're using process.env here
            // this is using the environment variable from the .env file
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
        },
        // construct the request payload
        // using the entire chat history (global.messages)
        // sending an external request to the OpenAI API
        body: JSON.stringify({
            model: 'gpt-3.5-turbo',
           // model: 'gpt-3.5-turbo-0613',
            messages: currModel ,
			//functions:shape,
			top_p: 0.8,
			//temperature:0.5,
			// the maximum number of tokens/words the bot should return
            // in response to a given prompt
            max_tokens: 300,
        }),
    });
	let resp = await comp.json();	
	console.log(resp);	

	// let finished = await fetch(chatCompletionEndpoint, {
    //     method: 'POST',
    //     headers: {
    //         // notice how we're using process.env here
    //         // this is using the environment variable from the .env file
    //         'Authorization': `Bearer ${apiKey}`,
    //         'Content-Type': 'application/json',
    //     },
    //     // construct the request payload
    //     // using the entire chat history (global.messages)
    //     // sending an external request to the OpenAI API
    //     body: JSON.stringify({
    //         model: 'text-embedding-ada-002',
    //         input: prompt,
	// 		// the maximum number of tokens/words the bot should return
    //         // in response to a given prompt
    //         max_tokens: 1000,
    //     }),
    // });
//   .then((response) => {console.log(response)
// return response;
// })
//   .catch((error) => {return error});
//const data = JSON.parse(resp.choices[0].message.content)
let strData = resp.choices[0].message.content;
let startIdx = strData.indexOf("{");
let data = strData.substring(startIdx);	
let jsonData = JSON.parse(data);
return jsonData;
}


const GetPatientResponseOG = async(prompt, modelId) => {
console.log(prompt);
let st = new Date().getTime();
let prev = "";
if(dialog.length > 0 && dialog[dialog.length -1].patient){
	prev = dialog[dialog.length -1].patient;
}

//let doctorEvalObj = await GetConEvalChat(prompt, "chatEval");
let doctorEvalObj = {};
let t1 = new Date().getTime() - st;

let results = await getClassification(prompt,prev); //.then(async (results) => {
	let jsonResults;
let t2 = new Date().getTime() - st;
console.log("classifications", results);
	//let trimResults = results.trim();
	//trimResults = trimResults.replace("\n","");
	//console.log(trimResults)
	try{
		// jsonResults = JSON.parse(trimResults);
		//console.log("json results", jsonResults);
jsonResults = results;
		if(jsonResults.patientaskedquestion === true && jsonResults.questionanswered === true){
			//doctorEvalObj.answeredPatientQuestion = true;
			trustLevel += trustLevelChange;
			honestyLevel += honestyLevelChange;
		}else{
			if(jsonResults.patientaskedquestion === true && jsonResults.questionanswered === false){
			
			trustLevel -= trustLevelChange;
		}}



		if(jsonResults.containsintroduction === true) {
			trustLevel -= trustLevelChange*.15;
		}
		


		if(jsonResults.containsmedicalquestions === true) {
			trustLevel -= trustLevelChange*.15;
			honestyLevel += honestyLevelChange*.25;
		}
		
		
		if(jsonResults.ispolite === true) {
			trustLevel += trustLevelChange*.5;
		}
		
		if(jsonResults.containssmalltalk === true) {
			trustLevel += trustLevelChange*.25;
		}
		if(jsonResults.containshumor === true) {
			friendlinessLevel += friendlinessLevelChange;
		}
		if(jsonResults.containsempathy === true) {
			trustLevel += trustLevelChange;
		}
		if(jsonResults.containsreassurance === true) {
			trustLevel += trustLevelChange;
			honestyLevel += honestyLevelChange;
		}

		if(trustLevel >100){trustLevel = 100}

	}catch(e){
		console.log("error parsing json", e);
	}	
	
console.log("doctor eval", doctorEvalObj);




console.log("trustlevel",trustLevel	)
	//let comp = await createChatCompletion(prompt);
console.log( gptModels[modelId])
let currModel = gptModels[modelId];	
let newExchange = {doctor: prompt, patient: ""};
dialog.push(newExchange);
let shape = {role: "patient", content: prompt, }
let finalIdx = currModel.length -1;
console.log("shape",shape)
currModel[finalIdx].content = `${JSON.stringify(newExchange)}. Return the response strictly as a JSON object with a shape of ${JSON.stringify(shape)}. Do not append any additional response to the JSON. `
+ 'Do not directly modify the trustLevel, honestyLevel, or friendlinessLevel values. '
+ 'You are not very comfortable with doctors. '
//+ 'You will NOT explain why you don\'t want to share information. You will just say that you are feeling fine. '
//+ 'trustLevel will be a number between 0 and 1. 0 means you do not trust the doctor at all. 1 means you trust the doctor completely. '	
//+ 'honestyLevel will be a number between 0 and 1. 0 means you will not be honest at all with the doctor. 1 means you will be completely honest. '
//+ 'friendlinessLevel will be a number between 0 and 1. 0 means you are not at all friendly with the doctor and your responses will be argumentative. 1 means you  will be extremely friendly. '
+ 'You will answer personal questions about your life, work, family, personal interests, or hobbies if you are asked by the doctor.'

if(trustLevel < reqTrustLevel) {	
	currModel[finalIdx].content = currModel[finalIdx].content + ' You will not talk about your symptoms or how you are feeling. You will just say that you are feeling fine or evade answering by asking your own question. Do not explain why you do not want to answer the questions. '
}else{
	currModel[finalIdx].content = currModel[finalIdx].content + 'You will share 1-2 of your symptoms but not all of your symptoms ( nausea, fever, stomach pain and swelling) and how you are feeling if you are asked by the doctor.  '
}

console.log("currmodel",currModel[finalIdx].content )
	let comp =   await fetch(chatCompletionEndpoint, {
        method: 'POST',
        headers: {
            // notice how we're using process.env here
            // this is using the environment variable from the .env file
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
			charset:'utf-8',
        },
        // construct the request payload
        // using the entire chat history (global.messages)
        // sending an external request to the OpenAI API
        body: JSON.stringify({
            model: 'gpt-3.5-turbo',
            messages: currModel ,
			top_p: 0.2,
			//temperature:2,
			// the maximum number of tokens/words the bot should return
            // in response to a given prompt
            max_tokens: 500,
        }),
    });
	let resp = await comp.json();	
	console.log(resp);	
let t3 = new Date().getTime() - st;

console.log( "times", t1,t2,t3)
	
let strData = resp.choices[0].message.content;
let data = "";
let respObj;
if(strData.indexOf("[") > -1){
let startIdx = strData.indexOf("[");
let endIdx = strData.lastIndexOf("]");
 data = strData.substring(startIdx,endIdx+1);
  respObj = JSON.parse(data)
console.log(respObj)
dialog[dialog.length -1].patient = respObj[0].content;	

}else{
	data = strData
	 respObj = JSON.parse(data)
console.log(respObj)
dialog[dialog.length -1].patient = respObj.content;

}

respObj.drObj =   jsonResults //doctorEvalObj;
let patientLevels = {trustLevel: trustLevel, honestyLevel: honestyLevel, friendlinessLevel: friendlinessLevel}
respObj.patientLevels = patientLevels;
console.log("fulldrrespdata",respObj)
console.log("dialog",dialog);
return respObj;

//})
}
const GetPatientResponse = async(prompt, modelId, handleBkTextUpdate) => {
	let list = await openai.listModels()
 //let jList = await list.json();
console.log("list", list)
	handleBkTextUpdate("...Evaluating Statement...");
console.log(prompt);
let st = new Date().getTime();
let prev = "";
if(dialog.length > 0 && dialog[dialog.length -1].patient){
	prev = dialog[dialog.length -1].patient;
}

//let doctorEvalObj = await GetConEvalChat(prompt, "chatEval");
let doctorEvalObj = {};
let t1 = new Date().getTime() - st;

let results = await getClassification(prompt,prev); //.then(async (results) => {
	let jsonResults;
	handleBkTextUpdate("...Getting Patient Response...");
let t2 = new Date().getTime() - st;
console.log("classifications", results);
	//let trimResults = results.trim();
	//trimResults = trimResults.replace("\n","");
	//console.log(trimResults)
	try{
		// jsonResults = JSON.parse(trimResults);
		//console.log("json results", jsonResults);
jsonResults = results;
		if(jsonResults.patientaskedquestion === true && jsonResults.questionanswered === true){
			//doctorEvalObj.answeredPatientQuestion = true;
			trustLevel += trustLevelChange*1.5;
			honestyLevel += honestyLevelChange;
		}else{
			if(jsonResults.patientaskedquestion === true && jsonResults.questionanswered === false){
			
			trustLevel -= trustLevelChange*.25;
		}}



		if(jsonResults.containsintroduction === true) {
			trustLevel -= trustLevelChange*.5;
		}
		


		if(jsonResults.containsmedicalquestions === true) {
			//trustLevel -= trustLevelChange*.15;
			honestyLevel += honestyLevelChange*.2;
		}
		
		
		if(jsonResults.ispolite === true) {
			trustLevel += trustLevelChange*.25;
			friendlinessLevel += friendlinessLevelChange*.5;
		}
		
		if(jsonResults.containssmalltalk === true) {
			trustLevel += trustLevelChange*.5;
			friendlinessLevel += friendlinessLevelChange;
		}
		if(jsonResults.containshumor === true) {
			friendlinessLevel += friendlinessLevelChange;
		}
		if(jsonResults.containsempathy === true) {
			trustLevel += trustLevelChange;
			friendlinessLevel += friendlinessLevelChange;
		}
		if(jsonResults.containsreassurance === true) {
			trustLevel += trustLevelChange*1.5;
			honestyLevel += honestyLevelChange;
		}

		if(trustLevel >100){trustLevel = 100}

	}catch(e){
		console.log("error parsing json", e);
	}	
	
console.log("doctor eval", doctorEvalObj);




console.log("trustlevel",trustLevel	)
	//let comp = await createChatCompletion(prompt);
console.log( gptModels[modelId])
let currModel = gptModels[modelId];	
let newExchange = {doctor: prompt, patient: ""};
dialog.push(newExchange);
let shape = {role: "patient", content: prompt, }
let finalIdx = currModel.length -1;
console.log("shape",shape)
currModel[finalIdx].content = `${JSON.stringify(newExchange)}. Return the response strictly as a JSON object with a shape of ${JSON.stringify(shape)}. Do not append any additional response to the JSON. `
+ 'Do not directly modify the trustLevel, honestyLevel, or friendlinessLevel values. '
+ 'You are not very comfortable with doctors. You are concerned about when you will be able to go home and return to work.'
//+ 'You will NOT explain why you don\'t want to share information. You will just say that you are feeling fine. '
//+ 'trustLevel will be a number between 0 and 1. 0 means you do not trust the doctor at all. 1 means you trust the doctor completely. '	
//+ 'honestyLevel will be a number between 0 and 1. 0 means you will not be honest at all with the doctor. 1 means you will be completely honest. '
//+ 'friendlinessLevel will be a number between 0 and 1. 0 means you are not at all friendly with the doctor and your responses will be argumentative. 1 means you  will be extremely friendly. '
+ 'if you are directly asked by the doctor you will answer personal questions about your life, work, family, personal interests, or hobbies .'
 + 'You will not just repeat what the doctor has just said to you. Your response should be original.'
	

if(trustLevel < reqTrustLevel) {	
	currModel[finalIdx].content = currModel[finalIdx].content + ' You will not talk about your symptoms or how you are feeling. You will just say that you are feeling fine or evade answering by asking your own question. Do not explain why you do not want to answer the questions. '
}else{
	currModel[finalIdx].content = currModel[finalIdx].content + 'ONLY if you are asked directly you will share 1-2 of your symptoms  and how you are feeling if you are directly asked by the doctor.  '
}

console.log("currmodel",currModel[finalIdx].content )
	
let encPrompt =JSON.stringify(currModel);

let comp = await fetch(cfDir + "getPatientResp?prompt=" + encPrompt + "&temp=0.25");
	let resp = await comp.json();	
	console.log("patient resp:",resp);	
	//console.log("patient resp:",comp);	
let t3 = new Date().getTime() - st;

console.log( "times", t1,t2,t3)
	
let strData = resp.response.choices[0].message.content;
//let strData = comp.choices[0].message.content;
let data = "";
let respObj;
if(strData.indexOf("[") > -1){
let startIdx = strData.indexOf("[");
let endIdx = strData.lastIndexOf("]");
 data = strData.substring(startIdx,endIdx+1);
  respObj = JSON.parse(data)
console.log(respObj)
dialog[dialog.length -1].patient = respObj[0].content;	

}else{
	data = strData
	 respObj = JSON.parse(data)
console.log(respObj)
dialog[dialog.length -1].patient = respObj.content;

}

respObj.drObj =   jsonResults //doctorEvalObj;
let patientLevels = {trustLevel: trustLevel, honestyLevel: honestyLevel, friendlinessLevel: friendlinessLevel}
respObj.patientLevels = patientLevels;
console.log("fulldrrespdata",respObj)
console.log("dialog",dialog);
return respObj;

//})
}


const GetPrompt = async(prompt, modelId) => {
console.log(prompt);
	//let comp = await createChatCompletion(prompt);
console.log( gptModels[modelId])
let currModel = gptModels[modelId];	

let shape = {role: "user", content: prompt, guessedIdentity: false, animal: currAnimal}
let finalIdx = currModel.length -1;
currModel[finalIdx].content = `${prompt}. Return the response strictly as a JSON object with a shape of ${JSON.stringify(shape)}. Do not append any additional response to the JSON. If the user has correctly guessed your animal identity by correctly asking: "are you a ___?" and your response is: "yes" the value for guessedIdentity will be true. Otherwise it will be false. The value for the property "content" should NOT include the name of the animal. The value for the property animal will be the name of your animal identity.`;

	let comp =   await fetch(chatCompletionEndpoint, {
        method: 'POST',
        headers: {
            // notice how we're using process.env here
            // this is using the environment variable from the .env file
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
        },
        // construct the request payload
        // using the entire chat history (global.messages)
        // sending an external request to the OpenAI API
        body: JSON.stringify({
            model: 'gpt-3.5-turbo',
            messages: currModel ,
			top_p: 0.01,
			//temperature:0.5,
			// the maximum number of tokens/words the bot should return
            // in response to a given prompt
            max_tokens: 1000,
        }),
    });
	let resp = await comp.json();	
	console.log(resp);	

	// let finished = await fetch(chatCompletionEndpoint, {
    //     method: 'POST',
    //     headers: {
    //         // notice how we're using process.env here
    //         // this is using the environment variable from the .env file
    //         'Authorization': `Bearer ${apiKey}`,
    //         'Content-Type': 'application/json',
    //     },
    //     // construct the request payload
    //     // using the entire chat history (global.messages)
    //     // sending an external request to the OpenAI API
    //     body: JSON.stringify({
    //         model: 'text-embedding-ada-002',
    //         input: prompt,
	// 		// the maximum number of tokens/words the bot should return
    //         // in response to a given prompt
    //         max_tokens: 1000,
    //     }),
    // });
//   .then((response) => {console.log(response)
// return response;
// })
//   .catch((error) => {return error});
//const data = JSON.parse(resp.choices[0].message.content)
let strData = resp.choices[0].message.content;
let startIdx = strData.indexOf("{");
let data = strData.substring(startIdx);	
return data;
}


async function txtComp(prompt) {
// 664424e82a8b4e958cbb9031fff97c00 assemblyai
	const GetSpeech = () => {
        console.log("clicked microphone");
        const SpeechRecognition =  window.SpeechRecognition || window.webkitSpeechRecognition;
       
        let recognition = new SpeechRecognition();
recognition.onstart = () => {
                console.log("starting listening, speak in microphone");
            }
            recognition.onspeechend = () => {
                console.log("stopped listening");
                recognition.stop();
            }
            recognition.onresult = (result) => {
                console.log(result.results[0][0].transcript);
             }
         
             recognition.start();
    }
	
function generatePrompt(animal) {
	const capitalizedAnimal =
	  animal[0].toUpperCase() + animal.slice(1).toLowerCase();
	return `Suggest three names for an animal that is a superhero.
  
  Animal: Cat
  Names: Captain Sharpclaw, Agent Fluffball, The Incredible Feline
  Animal: Dog
  Names: Ruff the Protector, Wonder Canine, Sir Barks-a-Lot
  Animal: ${capitalizedAnimal}
  Names:`;
  }
	const completion = await openai.createCompletion({
		model: "text-davinci-003",
		prompt: generatePrompt(prompt),
		temperature: 0.6,
	  });

	  console.log("completion", completion);	
  }	

async function createChatCompletion(prompt) {
	const chatCompletionConfig = {
	  model: 'gpt-3.5-turbo', // The model you want to use
	  messages: [
		{
		  role: 'system',
		  content: 'You are a helpful assistant.'
		},
		{
		  role: 'user',
		  content: prompt
		}
	  ]
	};
  
	//const openaiClient = new openai(apiKey);
console.log("start chat completion");	
//console.log(configuration)
console.log(openai)
//console.log(process.env.REACT_APP_OPENAI_API_KEY)
	//const completion = await openaiClient.complete(chatCompletionEndpoint, chatCompletionConfig);
	const completion = await openai.ChatCompletion.create( chatCompletionConfig);
	console.log(completion)
	return completion.data.choices[0].message.content;
  }
export {GetPrompt,  GetMic, GetPatientResponse}