import * as React from 'react';
import { useEffect, useState } from 'react';
import { Icon } from '@mui/material';
import AddTaskIcon from '@mui/icons-material/AddTask';

const Metric = (props) => {

	let item = props.item;

	return (<div className="metric">
		<div className="m-label">
{item.label}
		</div>
		
	{<div className="m-icon">
			<Icon><AddTaskIcon /></Icon>
<div className="m-value">{item.genValue}</div>
			 </div>
			}
	</div>)
}

export default Metric;