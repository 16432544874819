
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';


  
  const LevelIndicator = (props) => {
let bp = props.breakpoints;
let currVal = props.level;
let colorClass = "mid-level";
if(currVal < bp[0]){
	colorClass = "low-level";
}
if(currVal >= bp[1]){
	colorClass = "high-level";
}
let offset = 12;
if(currVal < 10){
	offset = 0;
}
if(currVal > 50){
	offset = 20;
}

return (<div>
<div className="ind-label">{props.label} </div>
<LinearProgress variant="determinate" className={colorClass} value={props.level} color="primary" style={{backgroundColor: "#330044"}} />
<div className="ind-value" style={{textAlign:'left', marginLeft:props.level-offset + "%"}}>{props.level}{props.hidePercent ? "":"%"}</div>
</div>
)
  }

  export default LevelIndicator;