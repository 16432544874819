let metricList = {
	ispolite:{label:"Was Polite", show: true, trustValue: 5,  cumulative:true, metric: "ispolite", genValue: .5,},
	questionanswered:{label:"Answered a Question", show:true, trustValue: 5,confidenceValue:15,  cumulative:true, metric: "questionanswered",genValue: 3, },
	containsintroduction: {label:"Introduction", show:true, trustValue: 5,  cumulative:false, metric: "containsintroduction",genValue: 2, },
	containssmalltalk: {label:"Small Talk", show:true, trustValue: 3, friendlinessValue:5, cumulative:true, metric: "containssmalltalk",genValue: 1, },
	containshumor:{label:"Humor", show:true, trustValue: 5,  cumulative:false,friendlinessValue:5, metric: "containshumor",genValue: 2, },
	containsmedicalquestions:{label:"Symptom Question", show:true, trustValue: 0,  cumulative:true, metric: "containsmedicalquestions",genValue: 1, },
	containspersonalquestions:{label:"Asked Personal Question", show:true, trustValue: 5,friendlinessValue:5,  cumulative:true, metric: "containspersonalquestions",genValue: 2, },
	containsempathy:{label:"Used Empathy", show:true, trustValue: 5, friendlinessValue:5, cumulative:true, metric: "containsempathy",genValue: 3, },
	containsreassurance:{label:"Reassured Patient", show:true, trustValue: 5,friendlinessValue:5, confidenceValue:15, cumulative:true, metric: "containsreassurance",genValue: 3, },	
}

export default metricList;

