import logo from './images/thackery_icon_1.png';
import patientImg from './images/patient_1.jpg';
import './App.css';

import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/base/Modal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { GetPrompt , GetConEval, GetMic, GetPatientResponse} from './js/api';
import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import LevelIndicator from './js/levelIndicator';
import { Icon } from '@mui/material';
import AddTaskIcon from '@mui/icons-material/AddTask';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import metricList from './js/metricList';
import Metric from './js/metric';


function App() {
	const [qInput, setQInput] = useState("");
  const [result, setResult] = useState();
  const [archive, setArchive] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [popupText,setPopupText] = useState("");
  const [openBkdrop, setOpenBkdrop] = useState(false);
  const [bkdropText, setBkdropText] = useState("...Evaluating Statement...");
  const [metrics, setMetrics] = useState({});
  const [editedMetricList, setEditedMetricList] = useState(metricList);
  const [usedMetricList, setUsedMetricList] = useState([]);
  const [currentMetricList, setCurrentMetricList] = useState([]);

  
  const [doctorBreakpoints, setDoctorBreakpoints] = React.useState([4,8]);
  const [patientResp, setPatientResp] = useState("");
  const [trustLevel,setTrustLevel ] = React.useState(0);
  const [trustBreakpoints, setTrustBreakpoints] = React.useState([40,65]);
  const [doctorLevel, setDoctorLevel] = React.useState(0);
  
  const [honestyLevel, setHonestyLevel] = React.useState(0);
  const [honestyBreakpoints, setHonestyBreakpoints] = React.useState([40,70]);
  
  const [friendlinessLevel, setFriendlinessLevel] = React.useState(0);
  const [friendlinessBreakpoints, setFriendlinessBreakpoints] = React.useState([40,65]);
  const archiveRef = React.useRef(null);


		
	const whatIsText = () =>{ return <div className="whatis-text">
	<div>Thackery is an on-going experiment exploring how interactions can be assessed and evaluated based on a set of abstract qualities. There are many professional contexts where training and assessment of personal interactions is vital (customer service, HR, sales, healthcare, etc). Thackery is an attempt to test methods for creating personas which can be interacted with using natural language and ways to evaluate the statements made by the user/trainee based on a customizable set of goals or parameters.
		</div>
		<div> In this scenario we look at a doctor’s interaction with a patient recovering from gall bladder surgery. The statements made by the doctor are analyzed to determine if they contain any of a list of qualities considered important for the evaluation (politeness, reassurance of the patient, asking personal questions, attempting to answer the patient’s questions, use of humor, etc). This evaluation contributes to an overall score but also changes qualities in the patient’s persona. In this example the patient is reticent to offer full details of his symptoms until the doctor’s responses increase his level of trust.
		</div> 
		<div>Both the persona and the qualities being evaluated can be easily customized.
		</div> 
		<div>This version uses the Chat GPT LLM (Large Language Model) as the basis for both interactions and evaluations. Despite not providing any training data the evaluations are reasonably accurate (although a bit slow). The expectation is that both speed and accuracy will improve significantly once training data is supplied.
		</div>
		</div>
}
	const instText = () =>{ return <div className="whatis-text">
	<div>You are a doctor following up on your patient after gall bladder surgery. The patient is reticent to be entirely honest about how he is feeling so you will need to interact with him in
		ways that will increase his trust.	</div>
		<div>To communicate you can hold down the microphone button while speaking and /or type directly into the Question area. 
			Your statement won't be processed until you click the Submit button. </div> 
		<div>
You'll be able to see the evaluation of your statement on the right side of the screen. 
The evaluation is based on an arbitrary set of qualities that are considered important for the interaction. These can be adapted for different environments.
		</div> 
		<div>	</div>
		</div>
}

const contactText = () =>{ return <div className="whatis-text">
<div>
	Are you interested in finding out more about how Thackery can be customized for your organization? 
</div>
<div>
	Drop us a line at <a href="mailto:info@uncannyvalley.design">info@uncannyvalley.design</a>
</div>
		</div>
}

 const openPopup= (text)=>{
	setPopupText(text);
	setOpenModal(true);
 }

  const handleCloseModal = ()=>{
	setOpenModal(false);
	  }

  
  
  const updateInput = (newItem) => {

setQInput(newItem);

  }

  const getSpeech = async (txt)=>{

	
	let cleanTxt = txt.replace(/\n/g, "");
	cleanTxt = cleanTxt.replace(",", "");
	//https://us-central1-thackery-e30ca.cloudfunctions.net/getTTSVoice
	let audioDataResp = await fetch("https://us-central1-thackery-e30ca.cloudfunctions.net/getTTSSpeech?vModel=en-US-Wavenet-D&vType=MALE&ttstext=" + encodeURIComponent(cleanTxt)) ;
	// console.log(audioDataResp)
	   let audioDataJSON =  await audioDataResp.json();
	   let audioData = audioDataJSON.response;
		console.log(audioData)
	if(audioData.length > 0){
	 let resp = audioData[0] ;
	  console.log(resp)
	  //console.log(resp.audioContent.data)
	  playOutput(resp.audioContent.data )
	}
  
  }

  const playOutput = (buffer)=>{
	let audioData = new Uint8Array(buffer) ;
	// console.log(audioData)
  
	let audioCtx ;
	// console.log("audiocontext", window.AudioContext.baseLatency)
   
	 audioCtx = new window.AudioContext()
  
	//   || window.webkitAudioContext)();
let source = audioCtx.createBufferSource();
audioCtx.decodeAudioData(audioData.buffer.slice(0), (buffer)=> {
  source.buffer = buffer;
  source.connect(audioCtx.destination);
  source.start(0);
 // let sources = this.state.sources;
  //sources.push(source)
  //this.setState({isPlaying:true, sources:sources})
  
})

source.onended=()=>{

// console.log("sound ended")
//this.setState({isPlaying:false})
}

  }

  const updateDoctorLevel = (drObj) => {
setCurrentMetricList(usedMetricList);
	console.log("drObj", drObj)
	let score = 0;
Object.keys(drObj).map((item, index) => {
	let details = editedMetricList[item];

	if(details){
		//console.log("usedMetricList", usedMetricList)
	if(details.cumulative === true || usedMetricList.indexOf(item) === -1){
if(details && drObj[item] === true){
	//console.log("item", item)
	//console.log("details", details)
 score = score + details.genValue;
 if(details.cumulative === false){
	let usedList = [...usedMetricList];
	usedList.push(item);
	setUsedMetricList(usedList);
 }

// delete	editedMetricList[item]
//metricList[item].genValue = 0;
}
//console.log(editedMetricList)
}
	}
	return item
})
  
setDoctorLevel(doctorLevel + score);

}
  const getArchive = () => {
	//console.log("archive", archive)
	if(archive.length === 0) {
		return ("")
	}else{
		
	// let archiveList = archive.map((item, index) => {
	// 	return {item} + "\n";
	// });
	let strArchive = archive.join("\n");

	//strArchive = strArchive + <div   ref={archiveRef} >END</div>;
	//console.log("strArchive", strArchive)
	return strArchive;
}
}

  const updateArchive = (newItem, label) => {
	//let labelledItem = label + ": " + newItem;
	
let newArchive = [...archive];

if(Array.isArray(newItem)){
	let newRows = [...newItem];

	newRows.map((r)=>{
		newArchive.push(r);
		return r
	})
}else{
newArchive.push(newItem);
}



console.log("new archive", newArchive);

//console.log("new archive", newArchive);
setArchive(newArchive);
//setQInput(newItem);

  }

  const handleBkTextUpdate= (newText) => {
	setBkdropText(newText);
}
useEffect(() => {
	
		if(archiveRef.current){
		console.log("scroll to bottom");
		 archiveRef.current.scrollTop = archiveRef.current.scrollHeight;
		}
}, [archive]);
  
  return (
    <div className="App">
      <header className="App-header">
	  <div className='logo-wrapper'>
        <img src={logo} className="logo" alt="logo" />
	</div>
		<div className='titles'>
		<div className="heading">Thackery</div>
		<div className='subtitle'>a qualitative analysis tool</div>
		</div>
		<div className='nav'>
			<div className="menu-item"  onClick={()=>openPopup(whatIsText)} >What Is Thackery?</div>
			<div className="menu-divider">|</div>
			<div className="menu-item" onClick={()=>openPopup(instText)} >Instructions</div>
			<div className="menu-divider">|</div>
			<div className="menu-item"  onClick={()=>openPopup(contactText)} >Contact</div>
		</div>
		
      </header>

	  <div className="dialog-wrapper">
		<div className="main-frame">
			<div className="dialog-frame">
				<div className="patient-frame">
					<div className="patient-statements">{patientResp}</div>
<img src={patientImg} className="patient-img" alt="patient" />
				</div>
				<div></div>
				</div>
				<div className="talk-frame">
				<div className='mic'>
	<GetMic onTentativeChange={updateInput} onFinalChange={updateInput} val={null} />
</div>
		<TextField
		name ="q"
          id="q"
		  variant="outlined"
		  className="q-input"
		  value={qInput}
            onChange={(e) => setQInput(e.target.value)}
          label="Enter Your Question"
          placeholder="Enter your question or response..."
          multiline
        />
		


</div>
<div className="submit-btn">

<Button 

variant="contained"

onMouseDown={async() => {
	setQInput("");
}}>Clear</Button>	
<Button 

variant="contained"

onMouseDown={async() => {
	setBkdropText("...Evaluating Statement...");
	setOpenBkdrop(true);
	updateArchive(qInput, "DOCTOR");
	let dialogDR = "DOCTOR: " + qInput;
		setBkdropText("...Getting Patient Response...");
	
	let resp = await GetPatientResponse(qInput, "patient1", handleBkTextUpdate);

	setQInput("");
	console.log("full response",resp);
	//setResult(resp);
	setOpenBkdrop(false);
	try{
		let jsonResp = resp ;//JSON.parse(resp);
		console.log("jsonResp", jsonResp);
		console.log("metrics" ,metrics)
		let arrDialog = [dialogDR + "\nPATIENT: " + jsonResp.content]
		setPatientResp(jsonResp.content);
		getSpeech(jsonResp.content);
		setMetrics( jsonResp.drObj);
		
		updateDoctorLevel(jsonResp.drObj);
		
		updateArchive(arrDialog)
		setTrustLevel(jsonResp.patientLevels.trustLevel);
		setHonestyLevel(jsonResp.patientLevels.honestyLevel);
		setFriendlinessLevel(jsonResp.patientLevels.friendlinessLevel);
		//
	}catch(e){
		console.log("error parsing json", e);
	}
	
}}>Submit</Button>	


				</div>
		</div>
		<div className='dashboard'>
<div className="archive">
	<div className="heading" >Dialog</div>
	<div className="archive-results">
			<TextField
			inputRef={archiveRef}
          id="dialogs"
		  variant="outlined"
		  height="150px"
		  value={getArchive()}
		  className="archive-list"
         // label="Enter Your Question"
          placeholder="dialog archive"
		  rows={8}
          multiline
		  
        />
	</div>


</div>
<div className="patient-stats">
<div className="heading" >Patient</div>
<div className="stats-wrapper">
<div className='indicator'>
	<LevelIndicator label="Trust" level={trustLevel} breakpoints={trustBreakpoints} color="#993333" />
</div>
<div className='indicator'>
	<LevelIndicator label="Confidence" level={honestyLevel} breakpoints={honestyBreakpoints} color="#993333" />	
</div>
<div className='indicator'>
	<LevelIndicator label="Friendliness" level={friendlinessLevel} breakpoints={friendlinessBreakpoints} color="#993333" />	
</div>
</div>
</div>
<div className="doctor-stats">
<div className="heading" >Doctor</div>
<div className="dr-level">
<div className='indicator dialog-score'>
	<LevelIndicator label="Dialog Score" level={doctorLevel} hidePercent={true} breakpoints={doctorBreakpoints} color="#993333" />	
</div>
</div>
<div className="stats-wrapper">

{metrics ? Object.keys(metrics).map((key, index) => {
	//console.log("usedMetricList", usedMetricList)
	if(metricList[key] && metrics[key] === true && currentMetricList.indexOf(key) === -1){
	let item = metricList[key];

	return <Metric key={"metric-" + index} item={item} />
	}else{
		return ""
	}	
}) : ""}
</div>
</div>
		</div>
	  </div>
	  <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={openBkdrop}
  
>
<CircularProgress color="inherit" />
<div className='bkdrop-text'>{bkdropText}</div>
</Backdrop>
<Modal 
	 open={openModal}
	 >
		<Box className="popup-box">
		<Icon className="close-btn" onClick={handleCloseModal}><HighlightOffIcon className="icon" /></Icon>
	<div>{popupText}</div>
	</Box>
		</Modal>
	</div>
	
  );
}

export default App;
